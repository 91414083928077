/* eslint-disable no-debugger */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { isEmpty } from 'lodash';
import uuid from 'react-uuid';

import SidebarItem from 'containers/main/NeyraDriveContainer/components/SidebarItem';
import { ReactComponent as NeyraIcon } from 'containers/main/Main/components/SettingsSidebar/assets/v2/neyra.svg';
import { ReactComponent as ChatRecordIcon } from 'containers/auth/NeyraChat/assets/chatRecord.svg';
import { ReactComponent as DeleteIcon } from 'components/VaultModal/assets/delete.svg';
import { ReactComponent as BiosIcon } from 'containers/auth/NeyraChat/assets/mainSideBar/record.svg';
import { ReactComponent as TelegramIcon } from 'static/assets/svg/telegram.svg';

import { setChatHistory, setConversationUid, setChats } from 'features/neyra';
import { trimLongText } from 'utils';
import useEnv from 'utils/hooks/use-env';
import {
  createConversation,
  deleteConversation,
  getAllConversations,
  getConversation,
  getPublicAvatar,
} from 'client-neyra';

import styles from './styles.module.scss';

const SidebarChat = () => {
  const [localAvatar, setLocalAvatar] = useState({
    avatarLoading: true,
    avatarId: 1,
  });
  const isDevNev = useEnv();
  const history = useHistory();
  const val = React.useRef();
  const dispatch = useDispatch();
  const { chats, conversationUid, conversationHistory } = useSelector(
    (store) => store.neyra
  );

  useEffect(() => {
    val.current = { avatarId: localAvatar.avatarId, conversationHistory };
  }, [localAvatar, conversationHistory]);

  useEffect(() => {
    const searchParams = new URLSearchParams(history.location.search);
    const searchAvatar = searchParams.get('avatar');

    if (searchAvatar) {
      getPublicAvatar({ params: { slug: searchAvatar } })
        .then((data) => {
          setLocalAvatar({ avatarLoading: false, avatarId: data.data.id });
        })
        .catch(() => {
          setLocalAvatar((oldStae) => ({ ...oldStae, avatarLoading: false }));
        });
    } else {
      setLocalAvatar((oldStae) => ({ ...oldStae, avatarLoading: false }));
    }

    return () => {
      const list = val.current.conversationHistory;
      if (!isEmpty(list)) {
        createConversation().then(({ data: { conversation_uid } }) => {
          dispatch(setChatHistory([]));
          dispatch(setConversationUid(conversation_uid));
        });
      }
    };
  }, []);

  useEffect(() => {
    if (!localAvatar.avatarLoading) {
      getAllConversations({
        params: { avatarId: localAvatar.avatarId },
      }).then((data) => {
        dispatch(setChats(data.data));
      });
    }
  }, [localAvatar]);

  const createNewChat = () => {
    if (isEmpty(conversationHistory)) {
      return;
    }
    createConversation({ body: { avatarId: localAvatar.avatarId } }).then(
      ({ data: { conversation_uid } }) => {
        dispatch(setChatHistory([]));
        dispatch(setConversationUid(conversation_uid));
        getAllConversations({
          params: { avatarId: localAvatar.avatarId },
        }).then((data) => {
          dispatch(setChats(data.data));
        });
      }
    );
  };

  const selectChat = (chatiTem) => {
    getConversation({ params: { uid: chatiTem.uid } }).then((res) => {
      const chatHistory = res.data;
      const historyList = [];
      chatHistory.history.reverse().forEach((item) => {
        const req = {
          id: uuid(),
          text: item.request,
          voice: false,
          timeStamp: item.created_at,
          isNeyro: false,
        };
        const res = {
          id: uuid(),
          text: item.response,
          voice: false,
          timeStamp: item.created_at,
          isNeyro: true,
        };

        historyList.push(req);
        historyList.push(res);
      });
      dispatch(setChatHistory(historyList));
      dispatch(setConversationUid(chatHistory.uid));
    });
  };

  const deleteConversationFunction = (uid) => {
    deleteConversation({
      params: { uid, userId: localAvatar.avatarId },
    }).then(() => {
      dispatch(setChats(chats.filter((item) => item.uid !== uid)));
      if (uid === conversationUid && conversationHistory.length) {
        createConversation({ body: { avatarId: localAvatar.avatarId } }).then(
          ({ data: { conversation_uid } }) => {
            dispatch(setChatHistory([]));
            dispatch(setConversationUid(conversation_uid));
          }
        );
      }
    });
  };

  const goToBios = () => {
    history.push('/bios');
  };

  const goToTelegraAnalizer = () => {
    history.push('/tg-analyze');
  };

  return (
    <>
      <SidebarItem
        item={{
          name: 'Chats',
          icon: <NeyraIcon />,
          action: () => {
            createNewChat();
          },
          isMain: true,
        }}
      />
      <SidebarItem
        item={{
          name: 'Bios',
          icon: <BiosIcon />,
          action: goToBios,
        }}
      />
      {isDevNev && (
        <SidebarItem
          item={{
            name: 'TG chat Analyzer',
            icon: <TelegramIcon />,
            action: goToTelegraAnalizer,
          }}
        />
      )}
      {!!chats?.length && <p className={styles.title}>Chats</p>}
      {chats?.map((item) => (
        <SidebarItem
          key={item.uid}
          item={{
            name: trimLongText(item.history.request, 24),
            icon: <ChatRecordIcon />,
            action: () => {
              selectChat(item);
            },
            backButton: true,
            backOnClick: () => {
              deleteConversationFunction(item.uid);
            },
            backIcon: <DeleteIcon />,
            className: styles.sidebarItem,
            tooltipText: 'Delete',
          }}
        />
      ))}
    </>
  );
};

export default SidebarChat;
