/* eslint-disable no-unused-vars */
/* eslint-disable no-debugger */
import React, { useState, useMemo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { selectFormFields, setFields } from 'features/form';
import { isEmpty, isString } from 'lodash';
import uuid from 'react-uuid';

import { ReactComponent as SpaceIcon } from 'containers/account/Space/assets/space.svg';
import { ReactComponent as MetaIcon } from 'containers/account/Space/assets/meta.svg';
import { ReactComponent as LinkIcon } from 'containers/account/Space/assets/link.svg';
import { ReactComponent as InboxIcon } from 'containers/account/Space/assets/inbox.svg';
import { ReactComponent as ViewIcon } from 'containers/account/Space/assets/view.svg';
import { ReactComponent as APIIcon } from 'containers/main/Main/components/SettingsSidebar/assets/v2/api.svg';

import MenuContent from './Components/MenuContent';
import MainContent from './Components/MainContent';
import EditContent from './Components/EditContent';
import APIContent from './Components/APIContent/APIContent';

import {
  selectNeyraAvatar,
  selectNeyraProfile,
  selectNeyraAvatarLoading,
} from 'features/neyra';
import {
  createAvatar,
  getPrivateAvatar,
  updateAvatar,
  uploadAvatar,
} from 'client-neyra';
import {
  // addPromtToHistory,
  generateSkybox360Effect,
  // generateAiEffectFromStableDiffusion,
  tokenUtilization,
} from 'store/aiGenerator/effects';
import {
  saveTokenPurchaseStatusToLocalStorage,
  localPublishKey,
} from 'utils/tokenPurchase';
import { TOKEN_COST } from 'config/token-cost';
// import {
//   selectTokenCountState,
// } from 'features/app';
import pusher from 'containers/ai-generator/utils/pusher_instance';
import useNotification from 'utils/hooks/use-notification';

import { NEYRA_AI_API_BASE } from 'constants/api-urls';
import { PUBLISH_SPACE_PRICE } from 'constants/bios-price';

import styles from './styles.module.scss';

const INITIAL_PUBLISH_VALUES = {
  name: '',
  mission: '',
  '3Dbg': '',
  domain: '',
};

export const MODES = {
  edit: 'edit',
  settings: 'settings',
  menu: 'menu',
  main: 'main',
  api: 'api',
};

const SpaceSidebar = () => {
  const [publishValues, setPublishValues] = useState(INITIAL_PUBLISH_VALUES);
  const [skyboxData, setSkyboxData] = useState();
  const [error, setError] = useState();
  const { addNotification } = useNotification();
  const [selectedSpace, setSelectedSpace] = useState(null);
  const [mode, setMode] = useState(MODES.main);
  const [loading, setLoading] = useState(true);
  const [panoramLoading, setPanoramLoading] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const fields = useSelector(selectFormFields);
  const avatarArray = useSelector(selectNeyraAvatar).filter(
    (item) => item.profile
  );
  const neyraUser = useSelector(selectNeyraProfile);
  const avatarLoading = useSelector(selectNeyraAvatarLoading);
  const isActivated = selectedSpace?.offer?.item === 'Publish';
  const isCreated = !!selectedSpace;
  const logo = '';

  const isDisabled = useMemo(
    () => !(!!publishValues.name && !!publishValues.mission),
    [publishValues]
  );

  const avatarFetch = {
    affinity: 5,
    code: '',
    llm: 1,
    mission: publishValues.mission,
    name: publishValues.name,
    description: skyboxData?.file_url || '',
    public: false,
    slug: uuid(),
    image: '',
  };

  useEffect(() => {
    const id = setTimeout(() => {
      setLoading(false);
    }, 400);

    return () => {
      clearTimeout(id);
    };
  }, []);

  useEffect(() => {
    if (!avatarLoading && isEmpty(avatarArray)) {
      setMode(MODES.edit);
    }
  }, [avatarLoading, avatarArray]);

  useEffect(() => {
    dispatch(
      setFields({
        spaceAvatar: selectedSpace?.profile?.image || '',
        spaceName: selectedSpace?.profile?.name || '',
        panoramaImage: selectedSpace?.profile?.description || '',
      })
    );
  }, [selectedSpace]);

  useEffect(() => {
    if (
      history.location.state?.successPayment &&
      history.location.state?.spaceId
    ) {
      selectSpace(history.location.state.spaceId);
      setMode(MODES.edit);
    }
  }, [history]);

  const selectSpace = async (id) => {
    try {
      const res = await getPrivateAvatar({ params: { id } });
      const selectedAvatar = res.data;
      setSelectedSpace(selectedAvatar);
      setPublishValues({
        name: selectedAvatar.profile.name,
        mission: selectedAvatar.profile.mission,
        domain: selectedAvatar.profile.slug,
        '3Dbg': selectedAvatar.profile.description
          ? selectedAvatar.profile.description.split('&promp=')[1]
          : '',
      });
      dispatch(
        setFields({
          spaceAvatar: selectedAvatar.profile.image,
          spaceName: selectedAvatar.profile.name,
        })
      );
    } catch (error) {
      console.warn(error);
    }
  };

  const spaceSidebar = [
    {
      name: 'Space',
      icon: <SpaceIcon />,
      action: () => {
        setPublishValues({
          name: selectedSpace.profile.name,
          mission: selectedSpace.profile.mission,
          domain: selectedSpace.profile.slug,
          '3Dbg': selectedSpace.profile.description
            ? selectedSpace.profile.description.split('&promp=')[1]
            : '',
        });
        setMode(MODES.edit);
      },
    },
    {
      name: 'Training',
      icon: <MetaIcon />,
      action: () => {},
      ref: '/memos',
    },
    {
      name: 'Links',
      icon: <LinkIcon />,
      action: () => {},
      ref: '/links',
    },
    {
      name: 'Inbox',
      icon: <InboxIcon />,
      action: () => {},
      ref: `/chat?avatar=${selectedSpace?.profile?.slug}`,
    },
    {
      name: 'API',
      icon: <APIIcon />,
      action: () => {
        setMode(MODES.api);
      },
      ref: ``,
    },
    // {
    //   name: 'Collect',
    //   icon: <CollectIcon />,
    //   action: () => {},
    // },
    {
      name: 'View',
      icon: <ViewIcon />,
      action: () => {
        window.open(`https://space.${window.location.host}`, '_blank');
      },
    },
  ];

  const onCreateAvatar = async () => {
    try {
      let avatarLogo;
      if (isString(fields?.newImage)) {
        avatarLogo = { data: { path: fields?.newImage } };
      } else if (fields?.newImage?.has('file')) {
        avatarLogo = await uploadAvatar({ formData: fields?.newImage });
      } else {
        avatarLogo = {
          data: { path: logo.substring(NEYRA_AI_API_BASE.length) },
        };
      }
      const newAvatatr = await createAvatar({
        body: { ...avatarFetch, image: avatarLogo.data.path },
      });
      const res = await getPrivateAvatar({
        params: { id: newAvatatr.data.id },
      });
      selectSpace(res.data.id);
    } catch (error) {
      console.warn(error);
    }
  };

  const onUpdateAvatar = async (params = {}) => {
    try {
      let avatarLogo;
      if (isString(fields?.newImage)) {
        avatarLogo = { data: { path: fields?.newImage } };
      } else if (fields?.newImage?.has('file')) {
        avatarLogo = await uploadAvatar({ formData: fields?.newImage });
      } else {
        avatarLogo = {
          data: { path: selectedSpace.profile.image },
        };
      }

      const publishedData = await updateAvatar({
        body: {
          ...avatarFetch,
          id: selectedSpace.id,
          email: neyraUser.email,
          image: avatarLogo.data.path,
          ...params,
        },
      });
      if (publishedData?.data?.public) {
        addNotification(
          isActivated ? 'Avatar was updated' : 'Avatar was published',
          'success'
        );
        setSelectedSpace((space) => ({
          ...space,
          offer: { ...space.offer, item: 'Publish' },
        }));
        dispatch(
          setFields({
            spaceAvatar: publishedData.data.image,
            spaceName: publishedData.data.name,
          })
        );
      }
    } catch (error) {
      const status = error?.response?.status;
      const errorMsg =
        error?.response?.data?.message ||
        error?.message ||
        'An unknown error occurred';
      const spaceId = error?.config?.data
        ? JSON.parse(error.config.data)?.id
        : undefined;
      if (status === 402) {
        saveTokenPurchaseStatusToLocalStorage(localPublishKey, spaceId);
        history.push('/wallet/buy-credits', { price: PUBLISH_SPACE_PRICE });
      }
    }
  };

  const onChnage = ({ target }) => {
    setPublishValues((value) => ({ ...value, [target.name]: target.value }));
  };

  const handleCreateUpdate = () => {
    if (selectedSpace) {
      onUpdateAvatar();
    } else {
      onCreateAvatar();
    }
  };

  const handlePublistAvatar = () => {
    onUpdateAvatar({ public: true });
  };

  const generate3Dbackground = async (prompt) => {
    // if (Number(userBalance) < skybox360Price) {
    //   // handleNotEnoughTokenModal(true);
    //   // setIsGenerationInProgress(false);
    //   return;
    // }

    try {
      setPanoramLoading(true);
      const data = await generateSkybox360Effect(prompt, '');
      const channel = pusher.subscribe(data.pusher_channel);
      channel.bind(data.pusher_event, function (res) {
        if (res.status === 'complete') {
          tokenUtilization(Number(TOKEN_COST['360ImageGenerator']))
            .then(() => {
              setSkyboxData({
                ...res,
                file_url: `${res.file_url}&promp=${prompt}`,
              });
              dispatch(
                setFields({ panoramaImage: `${res.file_url}&promp=${prompt}` })
              );
              pusher.unsubscribe(data.pusher_channel);
              setPanoramLoading(false);
            })
            .catch((err) => {
              setPanoramLoading(false);
            });
        }
        if (res.status === 'error' || res.status === 'abort') {
          pusher.unsubscribe(data.pusher_channel);
          setPanoramLoading(false);
          setError({ status: true, message: '' });
        }
      });
    } catch (error) {
      setPanoramLoading(false);
      setError({ status: true, message: '' });
      // setIsGenerationInProgress(false);
    }
  };

  if (loading) return '';

  const renderContent = () => {
    switch (mode) {
      case MODES.main:
        return (
          <MainContent
            INITIAL_PUBLISH_VALUES={INITIAL_PUBLISH_VALUES}
            avatarArray={avatarArray}
            setPublishValues={setPublishValues}
            selectSpace={selectSpace}
            setMode={setMode}
          />
        );
      case MODES.menu:
        return (
          <MenuContent
            selectedSpace={selectedSpace}
            setSelectedSpace={setSelectedSpace}
            spaceSidebar={spaceSidebar}
            setMode={setMode}
          />
        );
      case MODES.edit:
        return (
          <EditContent
            selectedSpace={selectedSpace}
            setPublishValues={setPublishValues}
            INITIAL_PUBLISH_VALUES={INITIAL_PUBLISH_VALUES}
            publishValues={publishValues}
            onChnage={onChnage}
            isDisabled={isDisabled}
            setMode={setMode}
            avatarArray={avatarArray}
            onCeateUpdate={handleCreateUpdate}
            onPublish={handlePublistAvatar}
            generate3Dbackground={generate3Dbackground}
            isCreated={isCreated}
            isActivated={isActivated}
            panoramLoading={panoramLoading}
          />
        );
      case MODES.api:
        return <APIContent setMode={setMode} selectedSpace={selectedSpace} />;
    }
  };

  return <div className={styles.spaceSidebar}>{renderContent()}</div>;
};

export default SpaceSidebar;
