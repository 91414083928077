// Refactoring №3
import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { getAccessToken } from 'utils/auth/get-token';
import { sendMessage } from 'store/neyra/effects';
import { createConversation } from 'client-neyra';
import { handleNeyraPromptHelperModal } from 'features/modals/modal-slice';
import {
  getRandomPrompt,
  studioDefaultPrompts,
} from 'containers/ai-generator/utils/autocompletePrompts';

import CN from 'classnames';
import styles from './style.module.scss';

const autocompleteOptions = ['Robot', 'Plant', 'Sci-Fi', 'Magic', 'HD 8K'];

type helperPropsType = {
  isNeyraPromtHelperOpen: boolean;
  prompt: string;
  generateAiHandler: (arg: string) => void;
  setGeneratedPrompt: (arg: string) => void;
};

export const NeyraPromptHelper = ({
  isNeyraPromtHelperOpen,
  prompt = '',
  generateAiHandler,
  setGeneratedPrompt,
}: helperPropsType) => {
  const { t } = useTranslation('owner');
  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState(prompt);
  const [activeOption, setActiveOption] = useState('');
  const [accessData, setAccessData] = useState({ tempUser: '', cid: '' });
  const { autoSpeaker } = useSelector(
    (state: { speaker: any }) => state.speaker
  );
  const dispatch = useDispatch();

  useEffect(() => {
    const initiate = async () => {
      try {
        const access_token = await getAccessToken();
        const res = await createConversation();
        setAccessData({
          tempUser: access_token as string,
          cid: res.data.conversation_uid,
        });
      } catch (error) {
        console.warn(error);
      }
    };
    initiate();
  }, []);

  const handlePromptGenerating = async () => {
    setMessage('');
    setMessages([]);
    const messageForNeyra = `Given a user-provided text prompt for image generation, create a new, more detailed prompt that adheres to the following guidelines:
Increased Detail: Enhance the prompt with additional visual aspects like style, colors, textures, composition, and lighting.
Originality: Encourage the generation of unique and non-stereotypical images.
Style Consistency: Maintain a style consistent with the user's prompt if specified.
Context Adherence: Ensure the generated image aligns with the context of the original prompt.
Conciseness: Restrict the length of the new prompt to no more than double the size of the original prompt.
Focus on Prompt Generation: The AI should solely generate a new prompt, avoiding answering questions or performing actions.
You should ALWAYS improve the input prompt from the user, if it is specified. You should always believe only the prompt, no questions or additional ideas, only the prompt response.
User prompt which should be improved: ${message}
`;
    const { tempUser, cid } = accessData;
    const controller = new AbortController();
    await sendMessage(
      tempUser,
      cid,
      messageForNeyra,
      setMessages,
      false,
      controller,
      autoSpeaker
    );
  };

  useEffect(() => {
    if (messages.length) {
      setMessage(messages[0].response);
    }
  }, [messages]);

  const onInputChange = (e) => {
    setMessage(e.target.value);
  };

  const handleImageGeneration = () => {
    generateAiHandler(message);
    setGeneratedPrompt(message);
    dispatch(handleNeyraPromptHelperModal(false));
  };

  const onModalClose = () => {
    dispatch(handleNeyraPromptHelperModal(false));
    const prompt = message ? message : '';
    setGeneratedPrompt(prompt);
  };

  const getStudioDefaultPrompt = (tag: string) => {
    const countOfWords = 5;
    const setOfRandomWords = new Set();
    while (setOfRandomWords.size !== countOfWords) {
      setOfRandomWords.add(
        studioDefaultPrompts[tag][
          getRandomPrompt(studioDefaultPrompts[tag].length)
        ]
      );
    }
    setMessage((prev) => prev + [...setOfRandomWords].join(', '));
  };

  return (
    <Modal
      isOpen={isNeyraPromtHelperOpen}
      onRequestClose={onModalClose}
      contentLabel="Analytics"
      className={styles.modal}
      overlayClassName={styles.overlay}
      shouldCloseOnOverlayClick
    >
      <div className={styles.content}>
        <div className={styles.optionsWrapper}>
          {autocompleteOptions.map((option) => (
            <button
              key={option}
              className={CN(
                styles.option,
                option === activeOption && styles.activeOption
              )}
              onClick={() => {
                setActiveOption(option);
                getStudioDefaultPrompt(option);
              }}
            >
              {option}
            </button>
          ))}
        </div>
        <textarea
          className={styles.textarea}
          name="prompt"
          rows={4}
          value={message}
          placeholder={`${t('studio.inputPlaceholder')}`}
          onChange={onInputChange}
        />
        <div className={styles.buttonsWrapper}>
          <button
            className={styles.button}
            onClick={handlePromptGenerating}
            disabled={!message}
          >
            {t('studio.generatePrompt')}
          </button>
          <button
            className={styles.button}
            onClick={handleImageGeneration}
            disabled={!message}
          >
            {t('studio.generateImage')}
          </button>
        </div>
      </div>
    </Modal>
  );
};
