/* eslint-disable no-unused-vars */
/* eslint-disable for-direction */
/* eslint-disable no-constant-condition */
/* eslint-disable import/no-extraneous-dependencies */

import uuid from 'react-uuid';
import { isEmpty } from 'lodash';
import { CarReader } from '@ipld/car';
import { downloadFile } from 'gdgateway-client/lib/es5';

import {
  createKnowledge,
  createQueue,
  getAllAvatars,
  getDataset,
  getKnowledge,
  getQueueFiles as getQueueFilesReq,
  getTalent,
  getUser,
  sendChatMessage,
  uploadAvatar,
  uploadFileQueue,
} from 'client-neyra';

import {
  setAiFiles,
  setProfile,
  setBios,
  startFetch,
  finishFetch,
  setAvatar,
  selectNeyraAvatar,
  setConversationHistory,
  createMessage,
} from 'features/neyra';

import edjsHTML from 'utils/editorjs';
import { store } from 'store/root-store';
import { titles } from 'config/neyra-bios';
import { getAccessToken } from 'utils/auth/get-token';
import { NEIRA_WHISPER_API } from 'constants/api-urls';
import { downloadFileData } from 'utils/file/library-callbacks';
import { addMessageToQueue } from 'features/speaker/speakerSlice';
import { LANG_KEY } from 'containers/account/Profile/components/NeyraLanguage';

import { getPreviewObject } from 'store/home/effects/file/get-preview-file-blob';
import { getDownloadOTT } from 'store/home/effects/files-upload/upload-file.effect';

const commandChecker = (message) => {
  const commandRegex = /^\/.+$/;
  const isCommand = commandRegex.test(message);
  return isCommand;
};

export const sendVoiceMessageEffect = async (form) => {
  try {
    const res = await fetch(NEIRA_WHISPER_API, {
      method: 'POST',
      body: form,
    });

    return res.json();
  } catch (error) {
    console.warn(error);
  }
};

export const sendMessageClear = async ({
  accessToken,
  abortController,
  avatarId,
  uid,
  msgText,
  modifiers = {},
  setMessage,
}) => {
  const myAccessToken = await getAccessToken();
  const controller = abortController || new AbortController();
  const signal = controller.signal;

  try {
    const response = await sendChatMessage({
      body: {
        avatarId: 1,
        themeId: 1,
        uid: uid,
        text: msgText,
        modifiers: { focus: '', ...modifiers },
        language: 'en',
      },
      accessToken: myAccessToken,
      callback: setMessage,
      signal,
    });
    return response;
  } catch (error) {
    if (error.name === 'AbortError') {
      return true;
    } else {
      console.error('Error occurred:', error);
      return error;
    }
  }
};

export const sendMessage = async (
  accessToken,
  uid,
  msgText,
  setMessage,
  autoScroll = true,
  abortController = null,
  autoSpeaker = false,
  handleCommandFlow,
  avatarId = 1,
  modifiers = {}
) => {
  const neyraMsgId = uuid();
  const myAccessToken = await getAccessToken();
  const controller = abortController || new AbortController();
  const lang = localStorage.getItem(LANG_KEY);
  const signal = controller.signal;

  try {
    setMessage
      ? setMessage((list) => {
          return [
            ...list,
            {
              id: neyraMsgId,
              text: '',
              voice: true,
              timeStamp: new Date(),
              isNeyro: true,
            },
          ];
        })
      : store.dispatch(
          createMessage({
            id: neyraMsgId,
            text: '',
            voice: true,
            timeStamp: new Date(),
            isNeyro: true,
          })
        );

    const callback = (finallyMsg) => {
      setMessage
        ? setMessage((list) =>
            list.map((item) =>
              item.id === neyraMsgId
                ? {
                    ...item,
                    text: item.text + finallyMsg,
                    response: item.text + finallyMsg,
                  }
                : item
            )
          )
        : store.dispatch(
            setConversationHistory({
              id: neyraMsgId,
              text: finallyMsg,
              response: finallyMsg,
            })
          );

      autoScroll && document?.getElementById(neyraMsgId)?.scrollIntoView();
      autoSpeaker && composeSentence(finallyMsg);

      const isMessageCommand = commandChecker(finallyMsg);
      if (isMessageCommand && handleCommandFlow) {
        const currentCommand = handleCommandFlow(finallyMsg);
        currentCommand && setMessage
          ? setMessage((list) =>
              list.map((item) =>
                item.id === neyraMsgId
                  ? {
                      ...item,
                      text: currentCommand.response,
                      response: currentCommand.response,
                    }
                  : item
              )
            )
          : store.dispatch(
              setConversationHistory({
                id: neyraMsgId,
                text: currentCommand.response,
                response: currentCommand.response,
              })
            );
      }
    };
    const response = await sendChatMessage({
      body: {
        avatarId: avatarId,
        themeId: 1,
        uid: uid,
        text: msgText,
        modifiers: { focus: '', ...modifiers },
        language: lang || 'en',
      },
      accessToken: myAccessToken,
      callback,
      signal,
    });
    return response;
  } catch (error) {
    if (error.name === 'AbortError') {
      return true;
    } else {
      console.error('Error occurred:', error);
      return error;
    }
  }
};

let unfinishedSentence = '';

const composeSentence = (finallyMsg) => {
  unfinishedSentence += finallyMsg;
  const punctuation = ['.', '?', '!'];

  for (let i = 0; i < unfinishedSentence.length; i++) {
    const char = unfinishedSentence[i];
    if (punctuation.includes(char)) {
      const sentencePart = unfinishedSentence.slice(0, i + 1);
      store.dispatch(addMessageToQueue(sentencePart));
      unfinishedSentence = unfinishedSentence.slice(i + 1);
      i = -1;
    }
  }
};

export const getNeyraAvatar = async (user_id) => {
  try {
    store.dispatch(startFetch());
    const res = await getAllAvatars();

    if (res?.data?.length) {
      store.dispatch(setAvatar(res?.data));
    }
    store.dispatch(finishFetch());
    return res?.data || {};
  } catch (error) {
    store.dispatch(finishFetch());
    console.error(error);
  }
};

export const getNeyraBios = async () => {
  try {
    let tempArray = [];
    let allowedFields = [
      'mind.life_work',
      'body.subconscious_self_expresion',
      'mind.balance',
      'body.stability',
      'body.low_of_success',
      'mind.low_of_success',
      'body.world_view',
      'mind.world_view',
      'mind.deep_values',
    ];
    let talents = {
      'mind.life_work': '/work.svg',
      'body.subconscious_self_expresion': '/self.svg',
      'mind.balance': '/balance.svg',
      'body.stability': '/stabl.svg',
      'body.low_of_success': '/low.svg',
      'mind.low_of_success': '/brain.svg',
      'body.world_view': '/view.svg',
      'mind.world_view': '/view.svg',
      'mind.deep_values': '/hand.svg',
    };
    const response = await getTalent();
    const mas = [];
    if (response.status === 'success') {
      const spheres = response.data.spheres;
      Object.keys(spheres).forEach((item) => {
        if (allowedFields.includes(item)) {
          tempArray.push({
            ...spheres[item],
            icon: talents[item],
          });
        }
      });
      let initm = [
        {
          simple: spheres['mind.life_work'],
          reverse: spheres['body.subconscious_self_expresion'],
          icon: '/work.svg',
          icon_reverse: '/self_expresion.svg',
        },
        {
          simple: spheres['mind.balance'],
          reverse: spheres['body.stability'],
          icon: '/balance.svg',
          icon_reverse: '/self_improvement.svg',
        },
        {
          simple: spheres['mind.low_of_success'],
          reverse: spheres['body.low_of_success'],
          icon: '/brain.svg',
          icon_reverse: '/star_low.svg',
        },
        {
          simple: spheres['mind.world_view'],
          reverse: spheres['body.world_view'],
          icon: '/view.svg',
          icon_reverse: '/world_view.svg',
        },
        {
          simple: spheres['mind.life_lessons'],
          reverse: spheres['mind.life_lessons'],
          icon: '/self.svg',
        },
        {
          simple: spheres['mind.deep_values'],
          reverse: spheres['body.deep_values'],
          icon: '/hand.svg',
          icon_reverse: '/numbers.svg',
        },
        {
          simple: spheres['mind.communication'],
          reverse: spheres['body.communication'],
          icon: '/hub.svg',
          icon_reverse: '/forum.svg',
        },
        {
          simple: spheres['mind.chaos_to_order'],
          reverse: spheres['body.chaos_to_order'],
          icon: '/chaos.svg',
          icon_reverse: '/order.svg',
        },
        {
          simple: spheres['mind.emotional_power'],
          reverse: spheres['body.vocation'],
          icon: '/power.svg',
          icon_reverse: '/vocation.svg',
        },
      ];
      initm.forEach((item) => {
        mas.push(titles[item.simple['talent_index']]);
        mas.push(titles[item.reverse['talent_index']]);
      });

      const res = [
        {
          simple: spheres['mind.life_work'],
          reverse: spheres['body.subconscious_self_expresion'],
          icon: '/work.svg',
          icon_reverse: '/self_expresion.svg',
        },
        {
          simple: spheres['mind.balance'],
          reverse: spheres['body.stability'],
          icon: '/balance.svg',
          icon_reverse: '/self_improvement.svg',
        },
        {
          simple: spheres['mind.low_of_success'],
          reverse: spheres['body.low_of_success'],
          icon: '/brain.svg',
          icon_reverse: '/star_low.svg',
        },
        {
          simple: spheres['mind.world_view'],
          reverse: spheres['body.world_view'],
          icon: '/view.svg',
          icon_reverse: '/world_view.svg',
        },
        {
          simple: spheres['mind.life_lessons'],
          reverse: spheres['mind.life_lessons'],
          icon: '/self.svg',
        },
        {
          simple: spheres['mind.deep_values'],
          reverse: spheres['body.deep_values'],
          icon: '/hand.svg',
          icon_reverse: '/numbers.svg',
        },
        {
          simple: spheres['mind.communication'],
          reverse: spheres['body.communication'],
          icon: '/hub.svg',
          icon_reverse: '/forum.svg',
        },
        {
          simple: spheres['mind.chaos_to_order'],
          reverse: spheres['body.chaos_to_order'],
          icon: '/chaos.svg',
          icon_reverse: '/order.svg',
        },
        {
          simple: spheres['mind.emotional_power'],
          reverse: spheres['body.vocation'],
          icon: '/power.svg',
          icon_reverse: '/vocation.svg',
        },
      ];

      return { ...res.data?.data, spheres: res };
    }

    return {};
  } catch (error) {
    console.error(error);
    return {};
  }
};

export const getNeyraUserInfo = () => async (dispatch) => {
  try {
    const res = await getUser();
    const profile = res.data;
    if (profile.id) {
      await getNeyraAvatar(profile.id);
      const bios = await getNeyraBios();
      dispatch(setProfile(profile));
      dispatch(setBios(bios));
    }
  } catch (error) {
    console.warn(error);
  }
};

export const getQueueFiles = async (avatarId) => {
  const dispatch = store.dispatch;
  const params = { user_id: avatarId, limit: 1000 };

  try {
    const res = await getQueueFilesReq({ params });

    dispatch(setAiFiles(res?.data || []));
  } catch (error) {
    throw new Error(error?.response?.data?.message || '');
  }
};

export const downloadFileToNeyraRead = async ({ dispatch, entity }) => {
  try {
    const controller = new AbortController();
    const {
      data: {
        jwt_ott,
        user_tokens: { token: oneTimeToken },
        gateway,
        upload_chunk_size,
      },
    } = await getDownloadOTT([entity.slug]);
    const { handlers } = downloadFileData;
    let cidData;

    const blob = await downloadFile({
      file: entity,
      oneTimeToken,
      jwtOneTimeToken: jwt_ott,
      endpoint: gateway.url,
      isEncrypted: false,
      callback: () => {},
      handlers,
      signal: controller.signal,
      carReader: CarReader,
      uploadChunkSize:
        upload_chunk_size[entity.slug] || gateway.upload_chunk_size,
      cidData,
    });
    const realBlob = new Blob([blob]);
    return realBlob;
  } catch (error) {
    throw new Error(error?.response?.data?.message || '');
  }
};

export const downloadMemoFileToNeyraRead = async ({ entity }) => {
  try {
    const data = await getPreviewObject({ file: entity, sendStatistic: false });

    const edjsHTMLParser = edjsHTML();
    const parsedMemoList = [
      `${data.name}\n`,
      ...edjsHTMLParser.parse(data),
    ].join();
    let blob = new Blob([parsedMemoList], { type: 'text/plain' });
    return blob;
  } catch (error) {
    throw new Error(error?.response?.data?.message || '');
  }
};

export const startNeyraRead = async ({ entity, dispatch, addNotification }) => {
  try {
    const avatar = selectNeyraAvatar(store.getState())[0];
    let blob;
    let file;
    if (entity.extension === 'memo') {
      blob = await downloadMemoFileToNeyraRead({ entity, dispatch });
      file = new File([blob], entity.name.replace('.memo', '.txt'));
    } else {
      blob = await downloadFileToNeyraRead({ entity, dispatch });
      file = new File([blob], entity.name);
    }

    const formData = new FormData();
    formData.append('file', file);
    let url;
    if (entity.mime.includes('image/') || entity.extension === 'webp') {
      formData.append('resource_id', 1);
      const response = await uploadAvatar({ formData });
      url = response?.data?.path;
    } else {
      const res = await uploadFileQueue({ formData });
      url = res.data.path || '';
    }

    const res = await createQueue({ body: { url, uid: entity.slug } });
    if (res.data.id) {
      getQueueFiles(avatar.id);
    }
  } catch (error) {
    addNotification(error.message, 'alert');
    throw new Error(error?.message || '');
  }
};

export const linkNeyraFetch = async (link) => {
  try {
    const response = await fetch(`https://r.jina.ai/${link}`, {
      method: 'GET',
    });
    return await response.text();
  } catch (error) {
    throw new Error(error?.message || '');
  }
};

export const getAllDataset = async () => {
  try {
    let datasetsArray = [];
    let memoDataset = [];
    let datasetsPage = 0;
    const pagination = {
      limit: 100,
      offset: 0,
    };

    for (let i = 1; i > 0; i++) {
      let datasetItem = null;
      try {
        const res = await getDataset({
          params: {
            ...pagination,
            offset: datasetsPage * pagination.limit,
          },
        });
        datasetItem = res.data;
      } catch (error) {
        console.error(error);
      }

      if (!datasetItem) {
        break;
      }

      const isMemoContain = datasetItem?.find((item) => item.title === 'memos');

      if (isMemoContain) {
        memoDataset.push(isMemoContain);
        break;
      } else {
        if (datasetItem.length < pagination.limit) {
          break;
        } else {
          datasetsPage = datasetsPage + 1;
        }
      }
    }

    if (isEmpty(memoDataset)) {
      return [];
    }

    for (let i = 0; i < memoDataset.length; i++) {
      const knowledgeArray = [];
      let knowledgePage = 0;
      const knowledgePagination = {
        limit: 100,
        offset: 0,
      };

      for (let x = 1; x > 0; x++) {
        const res = await getKnowledge({
          params: {
            ...knowledgePagination,
            offset: knowledgePage * knowledgePagination.limit,
            dataset_id: datasetsArray[i].id,
          },
        });
        const knowledgeItem = res?.data || [];
        if (knowledgeItem.length < knowledgePagination.limit) {
          knowledgeArray.push(...knowledgeItem);
          break;
        } else {
          knowledgeArray.push(...knowledgeItem);
          knowledgePage = knowledgePage + 1;
        }
      }

      memoDataset = memoDataset.map((item) => {
        if (item.id === memoDataset[i].id) {
          return {
            ...item,
            knowledges: knowledgeArray,
          };
        } else {
          return item;
        }
      });
    }

    return memoDataset;
  } catch (error) {
    console.warn(error);
  }
};

export const putDataKnowledge = async ({
  avatarId,
  title,
  message,
  knowledgeId,
}) => {
  try {
    const body = {
      dataset_id: knowledgeId,
      document: {
        title: title,
        body: message,
        tags: [],
      },
      avatar: [avatarId],
      paid: false,
    };
    const data = await createKnowledge({ body });

    return data;
  } catch (error) {
    console.warn(error);
  }
};
