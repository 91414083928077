import { initializeApiClient } from 'client-neyra';
import { axiosInstanceConfig } from 'client-neyra/dist/apiClient';
import { store } from 'store/root-store';
import { NEYRA_AI_API } from 'constants/api-urls';
import { getAccessToken } from 'utils/auth/get-token';
import { setErrors } from 'store/errors/actions/index';
import packageJson from '../../../package.json';
import { handleUnauthorizedError } from './handleUnauthorizedError';

const onRequest = async (config: any) => {
  const token = await getAccessToken();
  if (token) {
    config.headers.Authorization = token;
  }

  return config;
};

const onError = async (error: any) => {
  if (
    error?.response?.status === 401 ||
    (!error.response && error.code === 'ERR_NETWORK')
  ) {
    try {
      const config = error.config;
      if (config) {
        const refreshedResponse = await handleUnauthorizedError(
          config,
          'neyra'
        );
        return Promise.resolve(refreshedResponse);
      }
    } catch (refreshError) {
      return Promise.reject(refreshError);
    }
  }
  if (error?.response?.status === 403 || error?.response?.status === 412) {
    const me = store.getState().account.userInfo.entities;
    if (
      !(
        me.is_password_access &&
        me.password_access &&
        !me.password_access?.enabled
      )
    ) {
      store.dispatch(setErrors(error?.response?.data, error?.response?.status));
    }
  }

  return Promise.reject(error);
};

const apiClientConfig: axiosInstanceConfig = {
  baseURL: NEYRA_AI_API,
  frontend: 'web',
  frontendVersion: packageJson.version,
  onRequest,
  onError,
};

initializeApiClient(apiClientConfig);
